import React from 'react';

const Pos: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M19 3C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21V3Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 5C5 3.89543 5.89543 3 7 3H15C16.1046 3 17 3.89543 17 5V10C17 11.1046 16.1046 12 15 12H7C5.89543 12 5 11.1046 5 10V5ZM15 5H7V10H15V5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 14C5.5 13.4477 5.94772 13 6.5 13H7.5C8.05228 13 8.5 13.4477 8.5 14C8.5 14.5523 8.05228 15 7.5 15H6.5C5.94772 15 5.5 14.5523 5.5 14Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 14C9.5 13.4477 9.94772 13 10.5 13H11.5C12.0523 13 12.5 13.4477 12.5 14C12.5 14.5523 12.0523 15 11.5 15H10.5C9.94772 15 9.5 14.5523 9.5 14Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 17C5.5 16.4477 5.94772 16 6.5 16H7.5C8.05228 16 8.5 16.4477 8.5 17C8.5 17.5523 8.05228 18 7.5 18H6.5C5.94772 18 5.5 17.5523 5.5 17Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 20C5.5 19.4477 5.94772 19 6.5 19H7.5C8.05228 19 8.5 19.4477 8.5 20C8.5 20.5523 8.05228 21 7.5 21H6.5C5.94772 21 5.5 20.5523 5.5 20Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 17C9.5 16.4477 9.94772 16 10.5 16H11.5C12.0523 16 12.5 16.4477 12.5 17C12.5 17.5523 12.0523 18 11.5 18H10.5C9.94772 18 9.5 17.5523 9.5 17Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 20C9.5 19.4477 9.94772 19 10.5 19H11.5C12.0523 19 12.5 19.4477 12.5 20C12.5 20.5523 12.0523 21 11.5 21H10.5C9.94772 21 9.5 20.5523 9.5 20Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 14C13.5 13.4477 13.9477 13 14.5 13H15.5C16.0523 13 16.5 13.4477 16.5 14C16.5 14.5523 16.0523 15 15.5 15H14.5C13.9477 15 13.5 14.5523 13.5 14Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 17C13.5 16.4477 13.9477 16 14.5 16H15.5C16.0523 16 16.5 16.4477 16.5 17C16.5 17.5523 16.0523 18 15.5 18H14.5C13.9477 18 13.5 17.5523 13.5 17Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 20C13.5 19.4477 13.9477 19 14.5 19H15.5C16.0523 19 16.5 19.4477 16.5 20C16.5 20.5523 16.0523 21 15.5 21H14.5C13.9477 21 13.5 20.5523 13.5 20Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.29355 2.26082C2.50115 0.851734 3.72672 0 5.00906 0H16.9909C18.2733 0 19.4988 0.851708 19.7064 2.26077C19.8561 3.27689 20 4.82027 20 7C20 10.2515 19.6775 17.6585 19.5171 21.1437C19.4434 22.7446 18.1233 24 16.5239 24H5.47611C3.87665 24 2.55653 22.7446 2.48286 21.1437C2.3225 17.6585 2 10.2515 2 7C2 4.82029 2.14385 3.27693 2.29355 2.26082ZM5.00906 2C4.57344 2 4.315 2.26178 4.27219 2.55232C4.13809 3.46256 4 4.90791 4 7C4 10.1945 4.31948 17.5469 4.48075 21.0518C4.50514 21.5818 4.94229 22 5.47611 22H16.5239C17.0577 22 17.4948 21.5818 17.5192 21.0518C17.6805 17.5469 18 10.1945 18 7C18 4.90789 17.8619 3.46254 17.7278 2.5523C17.685 2.26177 17.4265 2 16.9909 2H5.00906Z"
      />
    </svg>
  );
};

export default Pos;
